<template>
  <div class="message-frame">
    <div class="menu">
      <div class="title">
        <i class="iconfont icon-notification"></i>
        <span>消息中心</span>
      </div>
      <div class="menu-list">
        <div
          class="menu-btn"
          :class="{ actived: selected === item.routerName }"
          v-for="item in list"
          :label="item.routerName"
          :key="item.routerName"
          @click="selected = item.routerName"
        >
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>

    <div class="body"><router-view /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          routerName: 'InformationTeacherMessage',
          name: 'menu.teacherMessage',
          routers: [
            'InformationTeacherMessage',
            'InformationTeacherMessageDetails'
          ]
        },
        {
          routerName: 'InformationClassMessage',
          name: 'menu.classMessage',
          routers: ['InformationClassMessage', 'InformationClassMessageDetails']
        },
        {
          routerName: 'InformationSystem',
          name: 'menu.systemMessage',
          routers: ['InformationSystem']
        }
      ],
      selected: 'InformationTeacherMessage'
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to) {
        if (to.name !== this.selected) {
          const item = this.list.find((m) => m.routers.includes(to.name))
          if (item) {
            this.selected = item.routerName
          }
        }
      }
    },
    selected(val) {
      if (val !== this.$route.name) {
        this.$router.push({ name: val })
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.message-frame {
  display: flex;
  .menu {
    width: 186px;
    background-color: #f9f9f9;
    padding: 25px;
    .title {
      font-size: 16px;
      color: #1f1f40;
      border-bottom: 1px solid #dcdcdc;
      padding: 10px 0;
      text-align: center;
      > span {
        margin-left: 8px;
      }
    }
    .menu-list {
      padding: 12px 0;
    }
    .menu-btn {
      padding: 12px 0;
      text-align: center;
      cursor: pointer;
      &:hover,
      &.actived {
        > span {
          color: #3b86ff;
          font-weight: bold;
          &:after {
            background-color: #3b86ff;
          }
        }
      }
      > span {
        color: #676767;
        font-size: 16px;
        position: relative;
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #676767;
          position: absolute;
          left: -20px;
          top: 4px;
        }
      }
    }
  }

  .body {
    flex: 1;
    margin-left: 26px;
  }
}
</style>
